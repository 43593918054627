import React from "react";
// import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({urlVideo, slogan, text}) {

    return (
      <div>
        <div className="w-full relative content_video flex justify-center md:mb-10 lg:mb-20">
          <video
            playsInline
            autoPlay
            muted
            loop
            className="w-full md:h-full object-cover after:bg-[#333]"
          >
            <source src={urlVideo} type="video/mp4" />
          </video>
          <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[100px] md:bottom-[50px] lg:bottom-[70px] md:text-start text-center text-white">
            <div className="w-full text-center p-4">
              <h1 className="text-[45px] md:text-[3em] lg:text-[4em]">
                {slogan}
              </h1>
              <p className="px-5 md:px-[10%]">{text}</p>
              {/* <ButtonContent btnStyle="three" /> */}
              <div className="text-center justify-center items-center flex">
                <a href="https://diazdetailingtv.as.me/" target="_blank" rel="noopener noreferrer">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fappoinment-compressed.jpg?alt=media&token=86c2908b-f68e-49a9-b084-db063b2f4ef6"
                    className="w-[200px] rounded"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default HeroVideo;